////////////////////////////////////////////////////
// ファイル名 : MainConfi.tsx
// 説明 : 入力された内容が問題ないか確認する画面
// 補足 : 
// 作成日: 2023/09/05
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from "axios";
import SendIcon from '@mui/icons-material/Send';
import ReplyIcon from '@mui/icons-material/Reply';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import witlogo from "../media/wit_logo.png"
import "./MainConfi.css"

const API_URI = process.env.REACT_APP_API_SV; // APIサーバーURI

export default function ConfiPage() {

    const navigate = useNavigate()
    const location = useLocation();
    const [nextWaiting, setNextWaiting] = useState(false) //ロード画面の表示フラグ格納用

    // 直URLされたら"/"に戻す
    useEffect(() => {
        if (!location.state) navigate("/")
    }, [location.state, navigate])

    // Backボタンの処理，値を前のページに返す
    const goBack = () => {
        navigate('/', {
            state:
            {
                eMail: location.state.eMail,
                reName: location.state.reName,
                reAddress: location.state.reAddress,
                reCountry: location.state.reCountry,
                reAccount: location.state.reAccount,
                beneBankName: location.state.beneBankName,
                beneBankBranch: location.state.beneBankBranch,
                beneBankAddress: location.state.beneBankAddress,
                beneBankCountry: location.state.beneBankCountry,
                swiftCode: location.state.swiftCode,
                interBank: location.state.interBank,
                other: location.state.other,
            }
        })
    }

    // SENDボタンの処理，APIサーバーへデータの送信
    const buttonHandler = () => {
        setNextWaiting(true)
        sendInfo()
    }

    // APIサーバーとの通信，入力情報を送信
    const sendInfo = async () => {
        await axios
            .post(
                API_URI + "/api/postworldbankallmail/",
                JSON.stringify({
                    email: location.state.eMail,
                    re_name: location.state.reName,
                    re_address: location.state.reAddress,
                    re_country: location.state.reCountry,
                    re_account: location.state.reAccount,
                    bene_bank_name: location.state.beneBankName,
                    bene_bank_branch: location.state.beneBankBranch,
                    bene_bank_address: location.state.beneBankAddress,
                    bene_bank_country: location.state.beneBankCountry,
                    swift_code: location.state.swiftCode,
                    inter_bank: location.state.interBank,
                    other: location.state.other,
                }),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                localStorage.setItem('display', 'ok')
                navigate("/succsess")
            })
            .catch((error) => {
                console.log(error);
                alert("Error:エラー")
            });
    };

    // URL直アクセスではない場合
    if (location.state) {
        return (
            <div className="confi_body">

                <img src={witlogo} alt='witlogo'/>

                <h1>下記の内容で送信します。</h1>
                <p>Kindly ensure your details are accurate.</p><br />

                <h2>・メールアドレス*</h2>
                <h3>{location.state.eMail}</h3>
                <h2>・報酬受取人名*</h2>
                <h3>{location.state.reName}</h3>
                <h2>・報酬受取人住所*</h2>
                <h3>{location.state.reAddress}</h3>
                <h2>・報酬受取人国名*</h2>
                <h3>{location.state.reCountry}</h3>
                <h2>・口座番号*</h2>
                <h3>{location.state.reAccount}</h3>
                <h2>・支払銀行名*</h2>
                <h3>{location.state.beneBankName}</h3>
                <h2>・支払銀行支店名*</h2>
                <h3>{location.state.beneBankBranch}</h3>
                <h2>・支払銀行住所*</h2>
                <h3>{location.state.beneBankAddress}</h3>
                <h2>・支払銀行国名*</h2>
                <h3>{location.state.beneBankCountry}</h3>
                <h2>・SWIFTコード*</h2>
                <h3>{location.state.swiftCode}</h3>
                <h2>・中継銀行</h2>
                <h3>{location.state.interBank}</h3>
                <h2>・その他</h2>
                <h3>{location.state.other}</h3>

                <Button size="large" variant="contained" endIcon={<ReplyIcon />} sx={{ mt: 2, mr: 2 }} onClick={() => goBack()}>Back</Button>
                <Button size="large" variant="contained" endIcon={<SendIcon />} sx={{ mt: 2, ml: 2 }} onClick={() => buttonHandler()}>SEND</Button>

                <footer className="footer">
                    <div className="md-flex md-justify-between">
                        <a href="https://www.witstudio.co.jp" className="footer__logo" target='_blank' rel="noreferrer">
                            <img src={witlogo} style={{ width: "70px" }} alt="witlogo" />
                        </a>
                    </div>
                    <hr />
                    <p className="copyright">
                        © {new Date().getFullYear()} <a href="https://www.witstudio.co.jp" target='_blank' rel="noreferrer">WIT STUDIO.</a> All Rights Reserved.
                    </p>
                </footer>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={nextWaiting}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}
////////////////////////////////////////////////////
// ファイル名 : router.tsx
// 説明 : URLとコンポーネントを紐づけるやつ
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////

import { Route, Routes } from 'react-router-dom';
import { VFC } from 'react';

import Main from './component/MainApp';
import Confirmation from './component/MainConfi'
import { SuccsessPage } from "./component/SuccsessForm"

import NotFound from "./component/NotFound"

const routes: VFC = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/succsess" element={<SuccsessPage />} />
      
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default routes({});
////////////////////////////////////////////////////
// ファイル名 : NotFound.tsx
// 説明 : 404エラー用画面
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////

import witlogo from "../media/wit_logo.png"
import "./NotFound.css"

export default function notFound404() {
    return (
        <div className="notfound_body">

            <img src={witlogo} alt="witlogo"/>

            <p>- 404 NotFound -</p>
        </div>
    );
}
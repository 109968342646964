////////////////////////////////////////////////////
// ファイル名 : SuccsessForm.tsx
// 説明 : 送信完了画面
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////

import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import witlogo from "../media/wit_logo.png"
import './SuccsessForm.css';

export const SuccsessPage = () => {

    const navigate = useNavigate()

    // APIサーバーからレスポンスが帰ってきたら表示する，または直URLアクセスを防ぐ
    useEffect(() => {
        if (localStorage.getItem('display') === 'ok') {
            localStorage.removeItem('display');
        } else {
            navigate("/404")
        }
    })

    return (
        <div className="succsesspage_body">

            <img src={witlogo} alt="witlogo"/>

            <p>海外口座登録申請を受け付けました。</p>
            <p>画面を閉じて終了してください。</p>
            <br />
            <p>Application for registration of overseas accounts has been accepted.</p>
            <p>Thank you.</p>
            <br />
            <footer className="footer">
                <div className="md-flex md-justify-between">
                    <a href="https://www.witstudio.co.jp" className="footer__logo" target='_blank' rel="noreferrer">
                        <img src={witlogo} style={{ width: "70px" }} alt="witlogo" />
                    </a>
                </div>
                <hr />
                <p className="copyright">
                    © {new Date().getFullYear()} <a href="https://www.witstudio.co.jp" target='_blank' rel="noreferrer">WIT STUDIO.</a> All Rights Reserved.
                </p>
            </footer>
        </div>
    );
}
////////////////////////////////////////////////////
// ファイル名 : MainApp.tsx
// 説明 : 申請内容の入力画面（一番初めの画面）
// 補足 : 
// 作成日: 2023/07/12
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////

import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import witlogo from "../media/wit_logo.png"
import './MainApp.css';

export default function MainView() {

    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(true)                               //初期同意画面

    const [eMail, setEMail] = useState("")                               //メールアドレスの入力値を格納
    const [eMailErr, setEMailErr] = useState(false)                      //メールアドレス入力欄のエラーフラグ格納用
    const eMailRef = useRef(null)                                        //dom参照用

    const [reName, setReName] = useState("")                             //報酬受取人名の入力値を格納
    const [reNameErr, setReNameErr] = useState(false)                    //報酬受取人名入力欄のエラーフラグ格納用
    const reNameRef = useRef(null)                                       //dom参照用

    const [reAddress, setReAddress] = useState("")                       //報酬受取人住所の入力値を格納
    const [reAddressErr, setReAddressErr] = useState(false)              //報酬受取人住所入力欄のエラーフラグ格納用
    const reAddressRef = useRef(null)                                    //dom参照用

    const [reCountry, setReCountry] = useState("")                       //報酬受取人国名の入力値を格納
    const [reCountryErr, setReCountryErr] = useState(false)              //報酬受取人国名入力欄のエラーフラグ格納用
    const reCountryRef = useRef(null)                                    //dom参照用

    const [reAccount, setReAccount] = useState("")                       //口座番号の入力値を格納
    const [reAccountErr, setReAccountErr] = useState(false)              //口座番号入力欄のエラーフラグ格納用
    const reAccountRef = useRef(null)                                    //dom参照用

    const [beneBankName, setBeneBankName] = useState("")                 //支払銀行名の入力値を格納
    const [beneBankNameErr, setBeneBankNameErr] = useState(false)        //支払銀行名入力欄のエラーフラグ格納用
    const beneBankNameRef = useRef(null)                                 //dom参照用

    const [beneBankBranch, setBeneBankBranch] = useState("")             //支払銀行支店名の入力値を格納
    const [beneBankBranchErr, setBeneBankBranchErr] = useState(false)    //支払銀行支店名入力欄のエラーフラグ格納用
    const beneBankBranchRef = useRef(null)                               //dom参照用

    const [beneBankAddress, setBeneBankAddress] = useState("")           //支払銀行住所の入力値を格納
    const [beneBankAddressErr, setBeneBankAddressErr] = useState(false)  //支払銀行住所入力欄のエラーフラグ格納用
    const beneBankAddressRef = useRef(null)                              //dom参照用

    const [beneBankCountry, setBeneBankCountry] = useState("")           //支払銀行国名の入力値を格納
    const [beneBankCountryErr, setBeneBankCountryErr] = useState(false)  //支払銀行国名入力欄のエラーフラグ格納用
    const beneBankCountryRef = useRef(null)                              //dom参照用

    const [swiftCode, setSwiftCode] = useState("")                       //SWIFTコードの入力値を格納
    const [swiftCodeErr, setSwiftCodeErr] = useState(false)              //SWIFTコード入力欄のエラーフラグ格納用
    const swiftCodeRef = useRef(null)                                    //dom参照用

    const [interBank, setInterBank] = useState("")                       //中継銀行の入力値を格納
    const [other, setOther] = useState("")                               //その他の入力値を格納

    //確認画面から戻ったときに値を引き継ぐ
    useEffect(() => {
        if (location.state) {
            setOpen(false)
            setEMail(location.state.eMail)
            setReName(location.state.reName)
            setReAddress(location.state.reAddress)
            setReCountry(location.state.reCountry)
            setReAccount(location.state.reAccount)
            setBeneBankName(location.state.beneBankName)
            setBeneBankBranch(location.state.beneBankBranch)
            setBeneBankAddress(location.state.beneBankAddress)
            setBeneBankCountry(location.state.beneBankCountry)
            setSwiftCode(location.state.swiftCode)
            setInterBank(location.state.interBank)
            setOther(location.state.other)
        }
    }, [location.state])

    //メールアドレス入力欄のエラーチェック
    const eMailError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setEMailErr(true);
        } else {
            if (String(inValue).match(/[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
                setEMailErr(false);
            } else {
                setEMailErr(true);
            }

        }
    }

    //報酬受取人名入力欄のエラーチェック
    const reNameError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setReNameErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setReNameErr(false);
            } else {
                setReNameErr(true);
            }
        }
    }

    //報酬受取人住所入力欄のエラーチェック
    const reAddressError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setReAddressErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setReAddressErr(false);
            } else {
                setReAddressErr(true);
            }
        }
    }

    //報酬受取人国名入力欄のエラーチェック
    const reCountryError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setReCountryErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setReCountryErr(false);
            } else {
                setReCountryErr(true);
            }
        }
    }

    //口座番号入力欄のエラーチェック
    const reAccountError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setReAccountErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setReAccountErr(false);
            } else {
                setReAccountErr(true);
            }
        }
    }

    //支払銀行名入力欄のエラーチェック
    const beneBankNameError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setBeneBankNameErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setBeneBankNameErr(false);
            } else {
                setBeneBankNameErr(true);
            }
        }
    }

    //支払銀行支店名入力欄のエラーチェック
    const beneBankBranchError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setBeneBankBranchErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setBeneBankBranchErr(false);
            } else {
                setBeneBankBranchErr(true);
            }
        }
    }

    //支払銀行住所入力欄のエラーチェック
    const beneBankAddressError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setBeneBankAddressErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setBeneBankAddressErr(false);
            } else {
                setBeneBankAddressErr(true);
            }
        }
    }

    //支払銀行国名入力欄のエラーチェック
    const beneBankCountryError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setBeneBankCountryErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9\s!-/:-@¥[-`{-~]*$/)) {
                setBeneBankCountryErr(false);
            } else {
                setBeneBankCountryErr(true);
            }
        }
    }

    //SWIFTコード入力欄のエラーチェック
    const swiftCodeError = (e: any) => {
        const inValue = e.target.value
        if (!inValue) {
            setSwiftCodeErr(true)
        } else {
            if (String(inValue).match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
                setSwiftCodeErr(false);
            } else {
                setSwiftCodeErr(true);
            }
        }
    }

    //semdボタン処理，入力値を次のページに渡す。
    const sendPage = () => {
        navigate('/confirmation', {
            state:
            {
                eMail: eMail,
                reName: reName,
                reAddress: reAddress,
                reCountry: reCountry,
                reAccount: reAccount,
                beneBankName: beneBankName,
                beneBankBranch: beneBankBranch,
                beneBankAddress: beneBankAddress,
                beneBankCountry: beneBankCountry,
                swiftCode: swiftCode,
                interBank: interBank,
                other: other,
            }
        })
    }

    return (
        <div className="mainapp_body">

            {/*初めに強制で表示されるホップアップ */}
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    利用規約(terms of service)
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        すべて半角英数字で記入してください。<br />
                        Please fill in half-width alphanumeric charcters.<br />
                        <br />
                        ◇お支払いに関して (Regarding payment)<br />
                        全額現金支払・口座振込 (Bank transfer ONLY)<br />
                        個人：毎月20 日 締め 翌月末 支払い<br />
                        法人：毎月末 締め 翌月末 支払い<br />
                        INDIVIDUAL ：Our cutoff date of billing is 20th of each month and shall be paid by the end of next month.<br />
                        CORPORATE ：Our cutoff date of billing is end of each month and shall be paid by the end of next month.<br />
                        <br />
                        弊社からの送金後、受取人の銀行口座に入金されるまで時間がかかることがありますので、ご注意ください。<br />
                        1～2週間経過しても入金がない場合は、お手数ですがご一報ください。<br />
                        After WIT completes the remittance, please be aware that there could be a delay between the completion of the remittance by WIT and the actual processing and appearance of the payment in the recipient's bank account.<br />
                        Please let us know if you have not received payment within a week or two.<br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.location.replace("https://www.google.com/")}>同意しない(Disagree)</Button>
                    <Button onClick={() => setOpen(false)}>
                        同意する(Agree)
                    </Button>
                </DialogActions>
            </Dialog>

            <img src={witlogo} alt='WITSTUDIO_LOGO' />

            <h1>海外口座登録申請フォーム</h1>
            <br />
            <h2>・メールアドレス*</h2>
            <TextField fullWidth ref={eMailRef} inputProps={{ maxLength: 256 }} value={eMail} size="small" error={eMailErr} type="email" id="outlined-basic" label="Email Address" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setEMail(e.target.value)
            }} onBlur={eMailError} />
            {eMailErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・報酬受取人名*</h2>
            <TextField fullWidth ref={reNameRef} inputProps={{ maxLength: 60 }} value={reName} size="small" error={reNameErr} id="outlined-basic" label="Recipient Name" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setReName(e.target.value)
            }} onBlur={reNameError} />
            {reNameErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・報酬受取人住所*</h2>
            <TextField fullWidth ref={reAddressRef} inputProps={{ maxLength: 161 }} value={reAddress} size="small" error={reAddressErr} id="outlined-basic" label="Address" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setReAddress(e.target.value)
            }} onBlur={reAddressError} />
            {reAddressErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・報酬受取人国名*</h2>
            <TextField fullWidth ref={reCountryRef} inputProps={{ maxLength: 27 }} value={reCountry} size="small" error={reCountryErr} id="outlined-basic" label="Country" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setReCountry(e.target.value)
            }} onBlur={reCountryError} />
            {reCountryErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・口座番号*</h2>
            <TextField fullWidth ref={reAccountRef} inputProps={{ maxLength: 30 }} value={reAccount} size="small" error={reAccountErr} id="outlined-basic" label="Bank Account Number" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setReAccount(e.target.value)
            }} onBlur={reAccountError} />
            {reAccountErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・支払銀行名*</h2>
            <TextField fullWidth ref={beneBankNameRef} inputProps={{ maxLength: 137 }} value={beneBankName} size="small" error={beneBankNameErr} id="outlined-basic" label="Beneficiary Bank Name" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setBeneBankName(e.target.value)
            }} onBlur={beneBankNameError} />
            {beneBankNameErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・支払銀行支店名*</h2>
            <TextField fullWidth ref={beneBankBranchRef} inputProps={{ maxLength: 137 }} value={beneBankBranch} size="small" error={beneBankBranchErr} id="outlined-basic" label="Bank Branch" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setBeneBankBranch(e.target.value)
            }} onBlur={beneBankBranchError} />
            {beneBankBranchErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・支払銀行住所*</h2>
            <TextField fullWidth ref={beneBankAddressRef} inputProps={{ maxLength: 161 }} value={beneBankAddress} size="small" error={beneBankAddressErr} id="outlined-basic" label="Bank Address" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setBeneBankAddress(e.target.value)
            }} onBlur={beneBankAddressError} />
            {beneBankAddressErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・支払銀行国名*</h2>
            <TextField fullWidth ref={beneBankCountryRef} inputProps={{ maxLength: 27 }} value={beneBankCountry} size="small" error={beneBankCountryErr} id="outlined-basic" label="Bank Country" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setBeneBankCountry(e.target.value)
            }} onBlur={beneBankCountryError} />
            {beneBankCountryErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・SWIFTコード*</h2>
            <TextField fullWidth ref={swiftCodeRef} inputProps={{ maxLength: 11 }} value={swiftCode} size="small" error={swiftCodeErr} id="outlined-basic" label="SWIFT code /BIC code" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setSwiftCode(e.target.value)
            }} onBlur={swiftCodeError} />
            {swiftCodeErr && <p style={{ textAlign: "right", color: "red" }}>正しい値を入力してください<br />（please enter the correct value）</p>}

            <h2>・中継銀行</h2>
            <TextField fullWidth inputProps={{ maxLength: 500 }} value={interBank} size="small" id="outlined-basic" label="Intermediary Bank" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setInterBank(e.target.value)
            }} />
            <p>※指定がある場合のみ記入<br />* Please provide information where applicable.</p>
            <br />
            <h2>・その他</h2>
            <TextField fullWidth inputProps={{ maxLength: 1000 }} value={other} size="small" id="outlined-basic" label="other" variant="outlined" sx={{ mb: 1, mt: 1 }} onChange={e => {
                setOther(e.target.value)
            }} />
            <p>*IBAN Code / CLABE code(Mexico) BSB code(Australia)  etc</p>

            <br />
            {/*空白じゃない，かつエラーじゃない場合はSENDボタンが活性化 */}
            <Button disabled={eMail.trim() === "" || reName.trim() === "" || reAddress.trim() === "" ||
                reCountry.trim() === "" || reAccount.trim() === "" ||
                beneBankName.trim() === "" || beneBankBranch.trim() === "" ||
                beneBankAddress.trim() === "" || beneBankCountry.trim() === "" || swiftCode.trim() === "" ||
                eMailErr || reNameErr || reAddressErr || reCountryErr || reAccountErr ||
                beneBankNameErr || beneBankBranchErr || beneBankAddressErr || beneBankCountryErr || swiftCodeErr}
                size="large" variant="contained" endIcon={<CheckCircleIcon />} sx={{ mt: 2 }} onClick={() => sendPage()}>
                Confirmation
            </Button>
            <div className='whiteBlank50' />
            <footer className="footer">
                <div className="md-flex md-justify-between">
                    <a href="https://www.witstudio.co.jp" className="footer__logo" target='_blank' rel="noopener noreferrer">
                        <img src={witlogo} style={{ width: "70px" }} alt="WITSTUDIO_LOGO" />
                    </a>
                </div>
                <hr />
                <p className="copyright">
                    © {new Date().getFullYear()} <a href="https://www.witstudio.co.jp" target='_blank' rel="noopener noreferrer">WIT STUDIO.</a> All Rights Reserved.　　<a href="https://www.witstudio.co.jp/privacypolicy/" target='_blank' rel="noopener noreferrer">プライバシーポリシー</a>
                </p>
            </footer>
        </div>
    );
}